/* global FileReader */
import moment from 'moment'
import ImageCompressor from '@xkeshi/image-compressor'

import ServerTime from '../services/ServerTime'
import { alphabeticalSort } from './index'
import store from '../services/store'
import {
  bidTypes,
  LOT_TIMER_ENDING_THRESHOLD,
  LOT_TIMER_ENDING_THRESHOLD_SEQUENTIAL
} from '../constants'
import jimp from 'jimp'

const imageCompressor = new ImageCompressor()

export function getRunningStatus (lot, auction, userId = null) {
  const state = auction.auctionType === 'synchronous' || auction.auctionType === 'sequentialOffline' ? auction.state : lot.state
  if (!auction) {
    return 'none'
  }
  if (state === 'closed') {
    return 'none'
  }
  if (state === 'open' && !lot.winner) {
    return 'none'
  }
  if (state === 'future') {
    return 'none'
  }
  let diff

  if (auction.auctionType !== 'synchronous') {
    diff = lot.staleTime || moment(lot.finishAt).valueOf() - moment().valueOf()
  } else {
    diff = moment(auction.finishAt).valueOf() - moment().valueOf()
  }
  let isEnding
  if (auction.auctionType === 'sequential') {
    isEnding = diff < LOT_TIMER_ENDING_THRESHOLD_SEQUENTIAL
  } else {
    isEnding = diff < LOT_TIMER_ENDING_THRESHOLD
  }
  if (isEnding) {
    if (isUserWinner(lot.participants, userId, lot.winner)) {
      return 'bided-ending'
    } else if (isUserParticipant(lot.participants, userId, lot.winner) && !isUserWinner(lot.participants, userId, lot.winner)) {
      return 'overbided-ending'
    } else {
      return 'ending'
    }
  }
  if (isUserWinner(lot.participants, userId, lot.winner)) {
    return 'bided'
  } else if (isUserParticipant(lot.participants, userId, lot.winner) && !isUserWinner(lot.participants, userId, lot.winner)) {
    return 'overbided'
  } else {
    return 'none'
  }
}

export function getResultMessage (lot, userId, auction) {
  const state = auction.auctionType === 'synchronous' ? auction.state : lot.state

  if (lot.withdrawn) {
    return 'withdrawn'
  }
  if(lot.soldBeforeAuction ){
    return 'sold'
  }
  if (state !== 'closed') {
    return ''
  }
  switch (lot.status) {
    case 'noBid':
      return 'no bid'
    case 'passed':
      return 'passed'
    case 'sold':
      if (lot.participants.find(id => id === userId)) {
        if (userId === lot.winner) {
          return 'won'
        } else {
          return 'lost'
        }
      } else {
        return 'sold'
      }
    default:
      // default case just for safety, should not actually appear in live auction
      return 'closed'
  }
}

export function getTimerState (lot, timeLeft, auction) {
  let state = null
  if (lot) {
    if (lot.withdrawn || lot.soldBeforeAuction) {
      state = null
    } else {
      switch (lot.state) {
        case 'future':
          state = 'FUTURE'
          break
        case 'open':
          //state = auction.auctionType === 'sequentialOffline' ? 'UPCOMING' : 'OPEN'
          state = 'OPEN'
          break
        case 'closed':
          state = 'CLOSED'
          break
        case 'futureInLive':
          let s = auction.lotOpeningSeconds
          let m = Math.floor(s / 60)
          let h = Math.floor(m / 60)
          timeLeft = { hours: h, minutes: m - 60 * h, seconds: s - 60 * m, done: false }
          state = formatTimeLeft(timeLeft)
          break
        case 'live':
        default:
          state = formatTimeLeft(timeLeft)
      }
    }
  }
  return state
}

export function getMyBid (lot, bids) {
  return bids.find(bid => bid.lot === lot._id)
}

export function getMyAutobid (lot, autobids) {
  return autobids.find(autobid => autobid.lot === lot._id)
}

export function makePrice (price, { bidding, cents = false, precision = 2 } = {}) {
  // handle edge cases e.g. Infinity
  if (!Number.isFinite(price)) {
    return '$0.00'
  }
  if (bidding && bidding === bidTypes.KG) {
    cents = true
  }
  if (cents) {
    return parseFloat(numberWithCommas(price.toFixed(precision))) + ' ¢'
  }
  return '$' + numberWithCommas((price / 100).toFixed(precision))
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isUserParticipant (participants, userId, winner) {
  // do not count user as participant if lot does not have a winner
  return winner && participants.some(user => user === userId)
}

export function isUserWinner (participants, userId, winner) {
  return userId === winner && isUserParticipant(participants, userId, winner)
}

export function formatTimeLeft (timeLeft) {
  if (timeLeft.done) {
    return 'CLOSING...'
  }
  return timeLeft.hours > 0
    ? `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
    : `${timeLeft.minutes}m ${timeLeft.seconds}s`
}

export function isAvailableEditLate (lot, auction) {
  if (!lot || !auction) {
    return false
  }
  if (lot.approved) {
    return ServerTime.getTime() < new Date(auction.editLateLotDisabledAt)
  } else {
    return false
  }
}

export function compressImage (file, options) {
  return new Promise((resolve, reject) => {
    imageCompressor.compress(file.file, options)
      .then((result) => {
        let reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onloadend = function () {
          file.url = reader.result
          file.mimeType = 'image/jpeg'
          delete file.blob
          resolve(file)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function rotateImage (url, angle) {
  return new Promise((resolve, reject) => {
    let correctUrl = url.replace(/^data:image\/\w+;base64,/, '')
    var arrayBuffer = Buffer.from(correctUrl, 'base64')
    jimp.read(arrayBuffer).then((result) => {
      result.rotate(360 - angle).getBase64(jimp.MIME_JPEG, function (err, src) {
        resolve(src)
        if (err) {
          reject(err)
        }
      })
    }).catch((err) => {
      reject(err)
    })
  })
}

export function getSexesByKinds (kindTypes, { sort = false,abbLambSale = false } = {}) {
  if (!Array.isArray(kindTypes)) {
    kindTypes = [kindTypes]
  }
  let stockCategories = store.getState().data.stockCategories
  let kindCategoriesArr = []
  if (stockCategories) {
    kindCategoriesArr = kindTypes.map(kindType => {
      let kindCategories
      if (abbLambSale) {
        kindCategories = stockCategories['abblambsheep']
      } else {
        kindCategories = stockCategories[kindType] || stockCategories.cattle
      }

      return Object.keys(kindCategories)
    })
  }
  let sexes = [].concat(...kindCategoriesArr)
  // remove any duplicates with Set
  sexes = Array.from(new Set(sexes))
  if (sort) {
    sexes = alphabeticalSort(sexes)
  }
  return sexes
}
export function getAvaillableStatesFromCatelogue (lots,AllStates) {
    let filteredLocations = [...new Set(AllStates)].filter(valueA =>
      lots.some(lot => {
        return lot.publicDetails.location.state === valueA
      })
  );
  return filteredLocations
}

export function getAvaillableRegionsFromCatelogue (lots,postcodes) {
  let regions = []
  postcodes.forEach(ele => {
    lots.forEach(lot => {
      if(lot.inlineDetails.postcode.toString() === ele.postcode.toString() )
      regions.push(`${ele.region} (${ele.state})`)
      })
  })
  regions = Array.from(new Set(regions));
 return regions
}

export function getStockCategoriesByKinds (kindTypes, { sort = false } = {}) {
  if (!Array.isArray(kindTypes)) {
    kindTypes = [kindTypes]
  }
  let stockCategories = store.getState().data.stockCategories
  let kindCategoriesArr = kindTypes.map(kindType => {
    let kindCategories = stockCategories[kindType] || stockCategories.cattle
    return [].concat(...Object.values(kindCategories))
  })
  let categories = [].concat(...kindCategoriesArr)
  // remove any duplicates with Set
  categories = Array.from(new Set(categories))
  if (sort) {
    categories = alphabeticalSort(categories)
  }
  return categories
}

export function getStockCategoriesBySex (kindType, sex, { sort = false,abbLambSale = false } = {}) {
  let stockCategories = store.getState().data.stockCategories
  let kindCategories
  if(abbLambSale){
     kindCategories = stockCategories['abblambsheep'] 
  }else {
    kindCategories = stockCategories[kindType] || stockCategories.cattle
  }
  let categories = (kindCategories && kindCategories[sex]) || []
  if (sort) {
    categories = alphabeticalSort(categories)
  }
  return categories
}

export function getDistrictsByState (state, { sort = false } = {}) {
  let locationDistricts = store.getState().data.locationDistricts
  let stateDistricts = locationDistricts[state] || []
  if (sort) {
    stateDistricts = alphabeticalSort(stateDistricts)
  }
  return stateDistricts
}

export function convertDollarToCentsPrice (price, lot) {
  return price / lot.publicDetails.weight.average
}

export function convertCentsToDollarPrice (price, lot) {
  return price * lot.publicDetails.weight.average
}

export function changeOrClearMetaTags (isClear) {
  let href = window.location.href
  let hrefArr = href && href.split('/')
  let arrLength = hrefArr.length
  let lotLink = `${hrefArr[arrLength - 3]}/${hrefArr[arrLength - 2]}/${hrefArr[arrLength - 1]}`
  const setContentAttr = (metaTag) => {
    if (metaTag && metaTag.content) {
      metaTag.content = `farmgate://${isClear ? '' : lotLink}`
    }
  }
  let tag = document.getElementsByTagName('meta')['ios-url']
  let tag2 = document.getElementsByTagName('meta')['twitter:app:url:iphone']
  let tag3 = document.getElementsByTagName('meta')['twitter:app:url:ipad'];
  [tag, tag2, tag3].forEach(item => {
    setContentAttr(item)
  })
}

export function calculateAverageDailyWeightGain (lot) {
  if (lot.optiweighAllowed && lot.optiweigh && lot.optiweigh.averageData) {
    const res = lot.optiweigh.averageData.reduce((acc, value) => (acc + value.averageDailyGain), 0) / lot.optiweigh.averageData.length
    return parseFloat(res.toFixed(2))
  }
}
